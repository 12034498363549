

































































import Vue from 'vue';
import axios from 'axios';
import { SmartTable, Config, Column, Sorting, PagingOptions } from 'rey-vue-smarttable';
import Modality from '@/models/Modality';
// @ts-ignore
import { Alert } from 'rey-vue-common';
import systemParameterService from '@/services/SystemParameterService';
import AuthenticationService from '@/services/AuthenticationService';

const LoadState = Object.freeze({ NotStarted: 0, Started: 1, Succeeded: 2, Failed: 3 });
export default Vue.extend({
  name: 'modalities',
  components: { SmartTable, Alert },
  data() {
    return {
      AuthenticationService: AuthenticationService,
      importEnabled: true,
      plcSyncEnabled: false,
      sendingToPlc: false,
      alertMessage: null as string | null,
      successMessage: null as string | null,
      successMessageReset: null as number | null,
      LoadState,
      smartTableConfig: new Config(
        (row) => row.modalityId,
        [
          new Column({
            title: this.$t('views.admin.modalities.tableHeaders.modalityId').toString(),
            fieldName: 'modalityId',
            fieldType: 'string',
            editable: () => false,
            hiddenInAddDialog: true
          }),
          new Column({
            title: this.$t('views.admin.modalities.tableHeaders.group').toString(),
            fieldName: 'group',
            fieldType: 'string',
            editable: () => !this.$data.importEnabled && AuthenticationService.isAuthenticated
          }),
          new Column({
            title: this.$t('views.admin.modalities.tableHeaders.name').toString(),
            fieldName: 'name',
            fieldType: 'string',
            editable: () => !this.$data.importEnabled && AuthenticationService.isAuthenticated
          })
        ],
        new Sorting('group', 'ascending'),
        new PagingOptions(25)
      ).withEfApiActions(axios, 'Modality'),
      selected: undefined as Modality | undefined
    };
  },

  async mounted() {
    systemParameterService.resetData();
    this.importEnabled = await systemParameterService.getSystemParameterBool(
      'EnableModalitiesImport'
    );

    this.plcSyncEnabled = await systemParameterService.getSystemParameterBool(
      'EnablePlcModalitiesSynchronization'
    );
  },

  methods: {
    sendToPlc() {
      this.sendingToPlc = true;
      axios
        .post('/api/actions/SendModalitiesTableToPlc', {})
        .then(() => {
          if (this.successMessageReset) {
            clearTimeout(this.successMessageReset);
            this.successMessageReset = null;
          }
          this.alertMessage = null;
          this.successMessage = this.$t('views.admin.modalities.sendSuccess');
          this.successMessageReset = setTimeout(() => (this.successMessage = null), 2500);
        })
        .catch(() => {
          this.alertMessage = this.$t('views.admin.modalities.sendError');
        })
        .finally(() => (this.sendingToPlc = false));
    }
  }
});
